import styled, { css } from "styled-components";

export const sansSerif = css`
  font-family: ${({ theme }) => theme.font?.sansSerif};
  color: ${({ theme }) => theme.textColor};
  font-style: normal;
  font-weight: normal;
  line-height: 200%;
`;

export const serif = css`
  font-family: ${({ theme }) => theme.font?.serif};
  color: ${({ theme }) => theme.textColor};
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
`;

export const contrast = css`
  font-family: ${({ theme }) => theme.font?.contrast};
  color: ${({ theme }) => theme.accentColor};
  font-style: normal;
  font-weight: normal;
  line-height: 80%;
`;

export const icons = css`
  font-family: "Custom Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 100%;
`;

export const H1 = styled.h1`
  ${serif};
  font-size: 48px;
`;

export const H2 = styled.h2`
  ${contrast};
  font-size: 144px;
`;

export const H3 = styled.h3`
  ${serif};
  font-size: 42px;
`;

export const H4 = styled.h4`
  ${contrast};
  font-size: 86px;
`;

export const H5 = styled.h5`
  ${serif};
  font-size: 36px;
`;

export const H6 = styled.h6`
  ${serif};
  font-size: 24px;
`;

export const Paragraph = styled.p`
  ${sansSerif};
  font-size: ${({ theme }) => (theme.breakPoints?.md ? "14px" : "16px")};

  //TODO create styled typewritter and remove following code from here
  > div {
    display: inline;
    margin-left: 10px;
    min-height: 4em;

    > span:first-child {
      font-size: 86px;
      ${contrast};
    }
    > span:last-child {
      background: ${({ theme }) => theme.accentColor};
      display: inline-block;
      height: 4em;
      width: 0.1em;
      position: absolute;
      margin-top: 0.07em;
      animation: blinker 1s ease-out infinite;

      @keyframes blinker {
        50% {
          opacity: 0;
        }
      }
    }
  }
`;

export const ExternalLink = styled.a`
  font-family: ${({ theme }) => theme.font?.serif};
  color: ${({ theme }) => theme.linkColor};
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;

  &:before {
    content: "»";
  }
  &:hover,
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.linkColor};
  }
`;
