import styled from "styled-components";

export const LayoutContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  position: relative;
`;
