import styled from "styled-components";
import { serif } from "../ui/typography";

export const DesktopNavigation = styled.nav`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: calc(2 * ${({ theme }) => theme.margin});

  > ul {
    display: flex;
    align-self: center;

    > li {
      display: inline-block;
      transition: all 0.15s ease-in-out;

      &:hover,
      &:focus {
        transform: scale(0.95);
      }
      &:active {
        transform: scale(0.9);
      }

      > a {
        font-family: ${({ theme }) => theme.font?.serif};
        font-weight: bold;
        text-decoration: none;
        margin-left: 2.5em;
        font-size: 18px;
        color: ${({ theme }) => theme.navLinkColor};
        transition: all 0.15s ease-in-out;

        &.active {
          color: ${({ theme }) => theme.navLinkHover};
        }
      }
    }
  }
`;

export const MobileNavigation = styled.div`
  margin-bottom: calc(2 * ${({ theme }) => theme.margin});
`;

export const MobileNavigationDrawer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  background: ${({ theme }) => theme.accentColor};
  color: ${({ theme }) => theme.background};
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 200;
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: translateY(0);
  }

  &.close {
    transform: translateY(-100%);
  }

  ul {
    display: block;
    padding: 0;

    li {
      padding: 0;
      margin-bottom: 1.5em;
      display: block;
      transition: all 0.15s ease-in-out;
      &:hover {
        transform: scale(0.95);
      }
      &:active {
        transform: scale(0.9);
      }

      > a {
        ${serif};
        font-size: 28px;
        color: ${({ theme }) => theme.background};
        text-decoration: none;
        transition: all 0.15s ease-in-out;

        &[aria-current] {
          cursor: default;
          opacity: 0.5;
        }
      }
    }
  }
`;
