import React, { FC, Fragment, ReactNode, useState } from "react";
import { DrawerToggle } from "./drawerToggle";
import { useBreakPoints } from "../breakPoints";
import Logo from "../ui/logo";
import { Link } from "gatsby";
import { DesktopNavigation, MobileNavigation, MobileNavigationDrawer } from "./navigationStyles";
import ThemeToggle from "../ui/themeToggle";

type NavigationLinks = {
  name: string;
  link: string;
};

const navigationLinks: NavigationLinks[] = [
  {
    name: "Warum mit uns?",
    link: "/about"
  },
  {
    name: "Unser Team",
    link: "/team"
  },
  {
    name: "Referenzen",
    link: "/work"
  },
  {
    name: "Kontakt",
    link: "/contact"
  }
];

const renderNavLinks = (): ReactNode =>
  navigationLinks.map(navLink => (
    <li key={navLink.name}>
      <Link to={navLink.link} title={navLink.name} activeClassName="active">
        {navLink.name}
      </Link>
    </li>
  ));

const Navigation: FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState);
  };

  const breakPoints = useBreakPoints();

  if (typeof window !== "undefined") {
    if (isDrawerOpen) {
      document.body.classList.add("noScroll");
      document.body.addEventListener("ontouchstart", e => e.preventDefault());
    } else {
      document.body.classList.remove("noScroll");
      document.body.removeEventListener("ontouchstart", e => e.preventDefault());
    }
  }

  const navigation = (
    <Fragment>
      <ThemeToggle />
      <DesktopNavigation role={"navigation"}>
        <Logo />
        <ul>{renderNavLinks()}</ul>
      </DesktopNavigation>
    </Fragment>
  );

  const mobileNavigation = (
    <MobileNavigation>
      <DrawerToggle onClick={toggleDrawer} isChecked={isDrawerOpen} />
      <Logo />
      <MobileNavigationDrawer className={isDrawerOpen ? "open" : "close"}>
        <nav role={"navigation"}>
          <ul>{renderNavLinks()}</ul>
        </nav>
        <ThemeToggle />
      </MobileNavigationDrawer>
    </MobileNavigation>
  );

  return breakPoints.md ? mobileNavigation : navigation;
};

export default Navigation;
