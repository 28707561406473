import { flipTheme, useTheme } from "../../layout/themeContext";
import React, { FC } from "react";
import { ThemeToggleButton } from "./themeToggleStyles";

const ThemeToggle: FC = () => {
  const { theme, setTheme } = useTheme();

  const toggleTheme = (): void => setTheme(flipTheme(theme));

  return (
    <ThemeToggleButton onClick={toggleTheme}>
      <i className={theme} title={`Switch to ${flipTheme(theme)} mode`} />
    </ThemeToggleButton>
  );
};

export default ThemeToggle;
