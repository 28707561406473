import { createGlobalStyle } from "styled-components";
import { ScTheme } from "./themes";

export const NormalizeStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    overflow-x: hidden;
    color: ${({ theme }) => (theme as ScTheme)?.textColor};
    background: ${({ theme }) => (theme as ScTheme)?.background};
    transition: all 0.15s ease-in-out;

    &.noScroll {
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }
  
  ul[class],
  ol[class] {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  img {
    max-width: 100%;
    display: block;
  }
  
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  
  a,
  button {
    cursor: pointer;
  }
`;
