import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Query } from "../../generated/graphql";
import Helmet from "react-helmet";
import "typeface-open-sans/index.css";
import "typeface-roboto-slab/index.css";
import Navigation from "../navigation";
import { NormalizeStyles } from "./themeContext/normalizeStyles";
import { LayoutContainer } from "./layoutStyles";

type LayoutProps = {
  pageTitle: string;
  pageDescription?: string;
};

const Layout: FunctionComponent<LayoutProps> = ({ children, pageTitle, pageDescription }) => {
  const data = useStaticQuery<Query>(graphql`
    query {
      site {
        siteMetadata {
          WebsiteTitle
          WebsiteAuthor
        }
      }
    }
  `);

  const siteMetadata = data.site?.siteMetadata;

  const webSiteTitle = siteMetadata?.WebsiteTitle ? siteMetadata.WebsiteTitle : "Loading Title";

  const title = `${webSiteTitle} • ${pageTitle}`;

  const url = typeof window !== "undefined" ? window.location.href : "";

  return (
    <LayoutContainer>
      <NormalizeStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>

        <link rel="canonical" href="http://devconsultberlin.de/" />
        <meta name="description" content={pageDescription} />

        <link rel="icon" type="image/png" href={require("../../../static/favicon-16x16.png")} sizes="16x16" />
        <link rel="icon" type="image/png" href={require("../../../static/favicon-32x32.png")} sizes="32x32" />
        <link rel="icon" type="image/png" href={require("../../../static/favicon-96x96.png")} sizes="96x96" />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={require("../../../static/share-thumbnail.jpg")} />
        <meta property="og:url" content={url} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={require("../../../static/share-thumbnail.jpg")} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Navigation />
      {children}
    </LayoutContainer>
  );
};

export default Layout;
