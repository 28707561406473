import styled from "styled-components";
import { icons } from "../typography";

export const ThemeToggleButton = styled.button`
  background: ${({ theme }) => theme.accentColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  bottom: 20px;
  right: ${({ theme }) => (theme.breakPoints?.lg ? "20px" : "initial")};
  left: ${({ theme }) => (theme.breakPoints?.md ? "20px" : "initial")};
  opacity: ${({ theme }) => (theme.breakPoints?.lg ? 0.75 : 1)};
  transition: all 0.15s ease-in;

  i {
    &:before {
      ${icons};
      color: ${({ theme }) => theme.background};
      display: block;
    }

    &.dark:before {
      content: "\\e901";
      font-size: 16px;
    }
    &.light:before {
      content: "\\e900";
      font-size: 14px;
    }
  }

  &:hover,
  &:active {
    opacity: 1;
  }
  &:active {
    transform: scale(0.8);
  }
`;
