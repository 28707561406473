import React, { FC } from "react";
import { DrawerToggleWrapper } from "./drawerToggleStyles";

type DrawerToggleProps = {
  onClick?: () => void;
  isChecked: boolean;
};

export const DrawerToggle: FC<DrawerToggleProps> = props => (
  <DrawerToggleWrapper onClick={props.onClick}>
    <input
      type="checkbox"
      checked={props.isChecked}
      onChange={e => (e.currentTarget.checked = props.isChecked)}
    />
    <div className="bun">
      <div className="burger" />
    </div>
  </DrawerToggleWrapper>
);
