import styled, { css } from "styled-components";

const size = "30px";

const pill = css`
  content: "";
  position: absolute;
  width: 100%;
  height: 12%;
  background: ${({ theme }) => theme.mainColor};
  border-radius: ${size};
  transition: all 0.5s cubic-bezier(0.1, 0.9, 0, 1.2);
`;

export const DrawerToggleWrapper = styled.div`
  display: block;
  position: absolute;
  width: ${size};
  height: ${size};
  z-index: 201;
  top: 30px;
  right: 20px;

  .bun {
    &:before {
      ${pill};
      top: 10%;
      right: 0;
    }
    &:after {
      ${pill};
      bottom: 10%;
      left: 0;
    }

    .burger {
      position: absolute;
      display: flex;
      align-items: center;
      height: ${size};
      width: ${size};

      &:before,
      &:after {
        ${pill};
      }
    }
  }
  input {
    display: block;
    position: absolute;
    opacity: 0;
    z-index: 1;
    width: ${size};
    height: ${size};
    cursor: pointer;

    &:checked ~ .bun {
      &:before,
      &:after {
        width: 0;
      }

      .burger {
        &:before {
          background: ${({ theme }) => theme.background};
          transform: rotate(135deg);
        }
        &:after {
          background: ${({ theme }) => theme.background};
          transform: rotate(45deg);
        }
      }
    }
  }
`;
