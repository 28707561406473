import styled from "styled-components";
import React, { FC } from "react";
import { Link } from "gatsby";
import { Theme } from "../../layout/themeContext/themes";
import { useTheme } from "../../layout/themeContext";

const LogoLink = styled(Link)`
  display: block;
  width: ${({ theme }): string => (theme.breakPoints?.xs ? "10em" : theme.breakPoints?.md ? "15em" : "18em")};
  transition: all 0.3s ease-in-out;

  &:active {
    transform: scale(0.95);
  }
`;

const Logo: FC = () => {
  const { theme } = useTheme();

  return (
    <LogoLink to={"/"}>
      <img
        src={require(`../../../../static/images/logo-${theme ? theme : Theme.LIGHT}.svg`)}
        alt="DevConsult Berlin"
      />
    </LogoLink>
  );
};

export default Logo;
